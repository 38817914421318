$site_color: #fff; // サイトで一番よく使われる色
$bg_color: #fff; // サイト全体の背景色
$font_color: #000; // 基本のフォントカラー
$link_color: #00f; // リンクテキストのカラー

$font_family: 'Noto Sans JP',
sans-serif; // 基本のフォントファミリー
$font_ja: sans-serif; // 日本語用フォントファミリー
$font_en: sans-serif; // 日本語用フォントファミリー

$inner_width: 1400; // PCレイアウトのコンテンツの幅
$sp_ref: 640; // スマホレイアウトのデザインデータの幅

@custom-media --min print,
screen and (min-width: 480px);

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');

@mixin f_around($pixels, $height: false, $weight: false, $spacing: false) {
	font-size: $pixels * 1px;

	@if $weight {
		font-weight: $weight;
	}

	@if $height {
		line-height: $height / $pixels;
	}

	@if $spacing {
		letter-spacing: ($spacing / 1000) * 1em;
	}
}

// 呼び出し例
// ＠include f_around(フォントサイズ, 行送り , 太さ, トラッキング)
