@import "__config";
@import "sanitize.css/sanitize.css";

html {
	font-size: 16px;
}

body {
	margin: 0;
	padding: 0;
	font-family: $font_family;
	font-weight: 500;
	line-height: 1.7;
	letter-spacing: 0.025em;
	color: $font_color;
	background: $bg_color;
}

audio,
canvas,
iframe,
img,
svg,
video,
embed,
object {
	vertical-align: top;
}

h1 {
	margin: 0;
}

ul {
	list-style-type: none;
	padding: 0;
}

h1,
h2,
h3,
h4,
p,
dl,
dt,
dd,
ul,
li {
	margin: 0;
	padding: 0;
}

li {
	&::before {
		content: "";

	}
}


img {
	max-width: 100%;
	height: auto;
}

a {
	color: inherit;
	transition: all 0.3s ease;
	text-decoration: none;
}

address,
small,
input,
textarea,
select {
	font: inherit;
}

button {
	font: inherit;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none;
}

:focus {
	outline: none;
}

.wrapper {
	position: relative;
	width: 100%;
	min-width: 100%;

	@media (--min) {
		min-width: #{$inner_width}px;
		margin: 0 auto;
	}
}

/* スクロールアニメーション用
.anm {
	opacity: 0;
	transition: all 0.5s ease-out;
	&.show {
		opacity: 1;
		transform: translate(0, 0);
	}
	&--up {
		transform: translate(0, 100px);
	}
	&--lr {
		transform: translate(-60px, 0);
	}
	&--rl {
		transform: translate(60px, 0);
	}
}
*/

/* アコーディオン用 サンプル
.acdn-sample {
	&.acdn-active {
		height: 0;
		overflow: hidden;
		transition: all 0.5s ease;
		@media (--min) {
			height: auto;
		}
	}
	&.open {
		height: auto;
	}
}
*/
.spVisible {
	display: block;

	@media (--min) {
		display: none;
	}
}

.pcVisible {
	display: none;

	@media (--min) {
		display: block;
	}
}

.preload {
	transition: none !important;
}

.anchor {
	padding: 80px 0 0;
	margin: -80px 0 0;

	@media (--min) {
		padding: 87px 0 0;
		margin: -87px 0 0;
	}
}

.header {
	position: fixed;
	z-index: 1;
	background: #fff;
	z-index: 3;
	width: (640 / $sp_ref) * 100vw;

	@media (--min) {
		width: 100%;
		height: 87px;
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 60px;
		background: #fff;

		@media (--min) {
			width: 1059px;
			margin: 0 auto;
			height: 87px;
		}
	}

	&__logo {

		@media (--min) {
			padding: 0;
		}
	}

	&__logoLink {
		display: flex;
		align-items: center;
		height: auto;
		padding: 0 0 0 10px;

		@media (--min) {}

	}

	&__logoImg {
		width: (227 / $sp_ref) * 100vw;
		height: auto;
		margin: 0 10px 0 0;

		@media (--min) {
			width: 247px;
		}
	}

	&__logoText {
		width: (118 / $sp_ref) * 100vw;
		height: auto;
		margin: 4px 0 0;

		@media (--min) {
			width: 138px;
		}
	}

	&__rightMenu {
		display: flex;
		align-items: center;
	}

	&__link {
		background: #1158a1;
		padding: 5px 15px;
		font-size: 12px;
		color: #fff;
		font-weight: 500;

		@media (--min) {
			padding: 5px 20px;
			font-size: 14px;
		}
	}
}

.headerNavi {
	transition: all 0.5s;
	position: fixed;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 60px;
	background: #1158A1;
	padding: 40px 0 0 0;
	color: #fff;
	transform: translateX(100%);
	visibility: hidden;

	@media (--min) {
		width: 500px;
		top: 87px;
		right: 0;
	}

	&.show {
		z-index: 3;
		visibility: visible;
		transform: translateX(0%);
	}

	&__list {
		padding: 0 30px 0 15px;
	}

	&__link {
		@include f_around(20, false, 400, false);
		display: block;
		text-align: center;
	}

	// &__item {
	// 	margin: 0 0 32px 0;
	// 	padding: 0 0 8px 23px;
	// 	border-bottom: 1px solid #fff;
	// 	position: relative;

	// 	&:nth-of-type(2),
	// 	&:nth-of-type(3),
	// 	&:nth-of-type(4),
	// 	&:nth-of-type(5),
	// 	&:nth-of-type(8) {
	// 		color: #ffffff38;
	// 		pointer-events: none;
	// 	}

	// 	&:last-of-type {
	// 		border-bottom: none;
	// 	}

	// 	&::before {
	// 		content: "";
	// 		display: inline-block;
	// 		width: 12px;
	// 		position: absolute;
	// 		right: 5px;
	// 		top: 12px;
	// 		height: 12px;
	// 		border-top: 2px solid #fff;
	// 		border-right: 2px solid #fff;
	// 		transform: rotate(45deg);
	// 	}
	// }


}

.humMenu {
	width: 70px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 4;

	@media (--min) {
		width: 80px;
		height: 80px;

		&:hover {
			cursor: pointer;
			opacity: 0.6;
		}
	}

	&__line {
		width: (53 / $sp_ref) * 100vw;
		height: 18px;

		@media (--min) {
			width: 53px;
		}

		span {
			background: #000;
			display: block;
			height: 4px;
			margin: 0 0 10px;
			border-radius: 4px;
			transition: all 0.5s;

			@media (--min) {
				margin: 0 0 10px;
			}

			&:last-child {
				margin: 0;
			}
		}
	}

	&.on {
		background: none;

		span:nth-of-type(1) {
			-webkit-transform: translateY(0px) rotate(-45deg);
			transform: translateY(1px) rotate(-45deg);
			background: #000;
			margin: 8px 0;
		}

		span:nth-of-type(2) {
			-webkit-transform: translateY(-20px) rotate(45deg);
			transform: translateY(-11px) rotate(45deg);
			background: #000;
		}
	}

	&__text {
		display: block;
		font-size: 8px;
		margin: 4px 0 0;
		text-align: center;
	}
}

.footer {


	&__info {
		background: #E7EBEB;
		padding: 60px 0;

		@media (--min) {
			padding: 70px 0 70px;
			display: flex;
			gap: 55px;
			justify-content: center;
			align-items: center;
		}
	}

	&__text {
		margin: 0 auto 8px;
		width: (532 / $sp_ref) * 100vw;

		&:last-of-type {
			font-size: 13px;
			margin: 0 auto;
			font-weight: 400;
		}

		@media (--min) {
			width: 420px;
			margin: 0;

			&:last-of-type {
				margin: 0;
				font-size: 19px;
				width: 500px;
			}
		}
	}

	&__company {
		width: (301 / $sp_ref) * 100vw;
		height: auto;

		@media (--min) {
			width: 395px;
		}
	}

	&__address {
		width: (532 / $sp_ref) * 100vw;
		height: auto;

		@media (--min) {
			width: 425px;
		}
	}

	&__logo {
		display: flex;
		padding: 40px 20px;
		justify-content: space-between;
		align-items: center;

		@media (--min) {
			justify-content: center;
			gap: 45px;
		}
	}

	&__logoImg {
		width: (305 / $sp_ref) * 100vw;
		height: auto;

		@media (--min) {
			width: 318px;
		}

		&--meitetsu {
			width: (248 / $sp_ref) * 100vw;

			@media (--min) {
				width: 258px;
			}
		}
	}

	&__copyRight {
		background: #000;
		display: block;
	}

	&__copyText {
		width: (557 / $sp_ref) * 100vw;
		margin: 0 auto;
		display: block;
		padding: 16px 0;
		height: auto;

		@media (--min) {
			width: 467px;
			padding: 25px 0;
		}
	}
}

.catch {

	&__video {
		padding: 60px 0 0;
		width: 100%;
		height: 100%;

		@media (--min) {
			padding: 87px 0 0;
		}
	}

	&__videoSp {
		width: 100%;

		@media (--min) {
			display: none;
		}
	}

	&__videoPc {
		display: none;

		@media (--min) {
			display: block;
			width: 100%;
		}
	}

	&__recruiting {
		background: url(../img/bg_catch_sp.jpg) no-repeat center;
		background-size: contain;
		height: (614 / $sp_ref) * 100vw;
		width: auto;
		padding: 30px 0 140px;

		@media (--min) {
			background: url(../img/bg_catch_pc.jpg) no-repeat center;
			background-size: cover;
			height: 805px;
			width: auto;
			padding: 120px 0 140px;
		}
	}

	&__prWrap {
		@media (--min) {

			display: flex;
			align-items: center;
			justify-content: center;
		}

	}

	&__img {
		width: (510 / $sp_ref) * 100vw;
		height: auto;
		margin: 0 auto;

		@media (--min) {
			width: 830px;
			margin: 0;
			position: relative;

			&::after {
				content: "";
				width: 1px;
				height: 60px;
				background: #fff;
				display: inline-block;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: -110px;
			}
		}
	}

	&__link {
		display: block;
	}

	&__text {
		width: (130 / $sp_ref) * 100vw;
		height: 22px;

		@media (--min) {}
	}

	&__title {
		width: (430 / $sp_ref) * 100vw;
		height: auto;
		margin: 0 20px 40px;

		@media (--min) {
			width: 64px;
			margin: 0 60px 40px 0;
		}
	}

	&__caption {
		width: (130 / $sp_ref) * 100vw;
		height: auto;

		@media (--min) {
			width: 138px;
			margin: 0 0 38px;
		}
	}


}

.modal-video {
	background-color: rgba(0, 0, 0, 0.9);
}

.secHeading {
	margin: 0 20px 60px 0;

	@media (--min) {
		margin: 0 auto 100px 0;
		max-width: 90%;

		&--career {
			margin: 0 auto 50px 0;
		}

		&--numbers {
			margin: 0 auto 110px 0;
		}

		&--company {
			margin: 0 auto 80px 0;
		}

		&--entry {
			margin: 0 auto 130px 0;
		}

		&--thanks {
			margin: 90px auto 130px 0;
		}

	}
}

.overview {
	padding: 90px 0 0;

	@media (--min) {
		padding: 115px 0 0;
	}

	&__text {
		font-size: 14px;
		line-height: 2;
		font-weight: 500;
		text-align: center;
		margin: 0 0 60px;

		@media (--min) {
			font-size: 16px;
		}
	}

	&__img {
		width: (600 / $sp_ref) * 100vw;
		margin: 0 auto 80px;

		@media (--min) {
			width: 1060px;
		}
	}
}

.job {
	@media (--min) {
		padding: 0 0 190px;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			display: inline-block;
			width: 123px;
			height: 123px;
			background: url(../img/bg_job_pc.png) no-repeat;
			background-position: right bottom;
			background-size: contain;
			right: 7%;
		}
	}

	&__leadTitle {
		font-size: 18px;
		font-weight: 700;
		text-align: center;
		position: relative;
		margin: 0 0 70px;

		&::before {
			content: "";
			position: absolute;
			display: inline-block;
			background: #000;
			height: 1px;
			width: (300 / $sp_ref) *100vw;
			left: 50%;
			transform: translateX(-50%);
			bottom: -17px;

			@media (--min) {
				width: 390px;
			}
		}
	}

	&__item {
		margin: 0 0 70px;

		&:last-of-type {
			margin: 0 0 100px;
		}

		@media (--min) {
			max-width: 90%;

			&:nth-of-type(odd) {
				margin: 0 auto 80px 0;
			}

			&:nth-of-type(even) {
				margin: 0 0 80px auto;

			}
		}
	}

	&__img {
		margin: 0 0 48px;

		@media (--min) {
			margin: 0 0 30px;
		}
	}

	&__schedule {
		margin: 0 20px;

		@media (--min) {
			max-width: 90%;
			margin: 0 auto 0 0;

			&--odd {
				margin: 0 0 0 auto;

			}
		}
	}
}

.workPlace {
	background: #9A1E4A;
	padding: 90px 0;

	@media (--min) {
		padding: 70px 0;
	}

	&__leadTitle {
		font-size: 18px;
		font-weight: 700;
		text-align: center;
		position: relative;
		margin: 0 0 70px;
		color: #fff;

		&::before {
			content: "";
			position: absolute;
			display: inline-block;
			background: #fff;
			height: 1px;
			width: (300 / $sp_ref) *100vw;
			left: 50%;
			transform: translateX(-50%);
			bottom: -17px;

			@media (--min) {
				width: 390px;
			}
		}
	}

	&__img {
		margin: 0 30px 20px;

		@media (--min) {
			width: 960px;
			margin: 0 auto 30px;
		}
	}

	&__text {
		width: (293 / $sp_ref) * 100vw;
		height: auto;
		margin: 0 auto;
	}
}

.career {
	padding: 100px 0;

	@media (--min) {
		padding: 117px 0 140px;
	}

	&__text {
		margin: 0 20px 30px;
		text-align: center;
		font-size: 14px;
		letter-spacing: 0.001em;
		line-height: 2;

		@media (--min) {
			font-size: 18px;
			margin: 0 0 40px;
		}
	}

	&__img {
		margin: 0 20px;

		@media (--min) {
			width: 900px;
			margin: 0 auto;
		}
	}
}

.numbers {
	background: #1158A1;
	padding: 100px 0;

	@media (--min) {
		padding: 110px 0 145px;
	}

	&__list {
		@media (--min) {

			width: 1060px;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			gap: 55px 30px;
			justify-content: center;
		}
	}

	&__listItem {
		margin: 0 20px 60px;

		&:last-of-type {
			margin: 0 20px;
		}

		@media (--min) {
			margin: 0;
			width: 512px;

			&:last-of-type {
				margin: 0;
			}
		}
	}
}

.recruit {
	padding: 0 0 90px;

	&__catch {
		margin: 0 0 60px;
	}

	&__leadTitle {
		font-size: 18px;
		font-weight: 700;
		text-align: center;
		position: relative;
		margin: 0 0 70px;
		color: #000;

		&::before {
			content: "";
			position: absolute;
			display: inline-block;
			background: #000;
			height: 1px;
			width: (300 / $sp_ref) *100vw;
			left: 50%;
			transform: translateX(-50%);
			bottom: -17px;

			@media (--min) {
				width: 390px;
			}
		}
	}

	&__listWrapper {
		@media (--min) {
			margin: 0 0 150px;

		}
	}

	&__list {
		margin: 0 20px 36px;

		@media (--min) {
			width: 860px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
		}
	}

	&__title {
		@media (--min) {
			margin: 0 40px 0 0;
			text-align: right;
			width: 157px;
		}
	}

	&__text {
		font-size: 15px;
		letter-spacing: 0.05em;
		line-height: 2;

		@media (--min) {
			width: 665px;
		}
	}

	&__character {
		display: flex;
		flex-direction: column;
		gap: 40px 0;

		@media (--min) {
			flex-direction: row;
			justify-content: center;
			gap: 56px;
		}
	}

	&__img {
		width: (490 / $sp_ref) * 100vw;
		margin: 0 auto;
		display: block;
		height: auto;

		@media (--min) {
			width: 410px;
		}
	}
}

.company {
	background: #9A1E4A;
	padding: 90px 0 90px;

	@media (--min) {
		padding: 110px 0 150px;
		position: relative;

		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			width: 303px;
			height: 303px;
			background: url(../img/bg_circle2_pc.png) no-repeat;
			background-size: contain;
			right: 4%;
			bottom: 100px;
		}
	}

	&__wrapper {
		@media (--min) {
			max-width: 1200px;
			display: flex;
			flex-direction: row-reverse;
			align-items: flex-end;
			gap: 70px;
			margin: 0 auto;
			position: relative;

			&::before {
				content: "";
				display: inline-block;
				position: absolute;
				width: 123px;
				height: 123px;
				background: url(../img/bg_circle1_pc.png) no-repeat;
				background-size: contain;
				left: 0;
				top: 80px;
			}
		}
	}

	&__item {
		margin: 0 20px 30px;

		&:last-of-type {
			margin: 0 20px;
		}

		@media (--min) {
			margin: 0;
			display: flex;
			gap: 0 40px;

			&:last-of-type {
				margin: 0;
			}
		}
	}

	&__text {
		font-size: 15px;
		line-height: 2;
		color: #fff;

		@media (--min) {
			width: 400px;
		}
	}

	&__title {
		font-size: 15px;
		line-height: 2;
		color: #fff;

		@media (--min) {
			width: 80px;
		}
	}

	&__detail {
		margin: 0 0 60px;

		@media (--min) {
			margin: 0;
		}
	}

	&__img {
		margin: 0 auto;
		width: (500 / $sp_ref) * 100vw;

		@media (--min) {
			width: 645px;
			margin: 0 auto 0;
		}
	}
}

.entry {
	padding: 90px 0 90px;

	&__text {
		text-align: center;
		margin: 0 0 70px;
	}

	&__compText {
		text-align: center;
		margin: 0 0 64px;

		@media (--min) {
			font-size: 36px;

		}
	}

	&__policyBtn {
		display: block;
		position: relative;
		width: (470 / $sp_ref) * 100vw;
		height: (60 / $sp_ref) * 100vw;
		margin: 64px auto;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		color: #fff;
		background: #9A1E4A;
		font-weight: 300;

		&::after {
			content: "〉";
			position: absolute;
			right: 3%;
		}

		@media (--min) {
			width: 450px;
			height: 60px;
			font-size: 24px;

			&::after {
				right: 5%;
			}
		}
	}
}

.contactForm {

	@media (--min) {
		padding: 20px 0 140px;
	}

	&__list {
		width: (490 / $sp_ref) *100vw;
		margin: 0 auto 16px;



		@media (--min) {
			width: 565px;
			margin: 0 auto 20px;

			&:last-of-type {
				margin: 0 auto;

			}
		}

		&--confirm {
			border-bottom: 1px solid #000;
			padding: 20px 0;
			margin: 0 auto;

			&:first-of-type {
				border-top: 1px solid #000;
			}

			@media (--min) {
				display: flex;
				width: 650px;
				gap: 75px;
				padding: 25px 0;
				justify-content: center;

				&:last-of-type {
					margin: 0 auto 90px;
				}
			}
		}

		&--name {
			width: (233 / $sp_ref) * 100vw;

			@media (--min) {
				width: 270px;
			}
		}
	}

	&__nameWrap {
		width: (490 / $sp_ref) *100vw;
		display: flex;
		margin: 0 auto;

		@media (--min) {
			width: 565px;
		}
	}

	&__listTitle {
		@include f_around(16, 24, 500, false);
		margin: 0 0 8px;

		@media (--min) {
			@include f_around(18, 26, 500, false);
		}

		&--confirm {
			@media (--min) {
				width: 190px;
				text-align: right;
				margin: 0;
				font-size: 21px;
			}
		}

	}

	&__listInput {
		input[type="text"] {
			font-size: 16px;
			border: solid 1px #ccc;
			width: 100%;
			padding: 0 8px;
			height: 26px;
		}

		textarea {
			font-size: 16px;
			border: solid 1px #ccc;
			width: 100%;
			height: 195px;
		}

		&--confirm {
			@media (--min) {
				width: 280px;
			}
		}

		& ._error {
			font-size: 90%;
			color: #f02f44;
		}
	}

	&__btnWrap {
		width: (490 / $sp_ref) *100vw;
		display: flex;
		margin: 0 auto;

		@media (--min) {
			gap: 20px;
			width: 650px;
		}
	}

	&__btn {
		display: block;
		width: (242 / $sp_ref) * 100vw;
		margin: 60px auto 0;
		padding: 10px 0;
		color: #fff;
		text-align: center;
		background: #205376;

		@media (--min) {
			width: 282px;

			&:hover {
				opacity: 0.6;
			}
		}

		&--confirm {
			width: (220 / $sp_ref) * 100vw;

			@media (--min) {
				width: 280px;
				margin: 0 auto;
				font-size: 21px;
			}
		}

		&--submit {
			background: #9A1E4A;
		}
	}

	&__nes {
		color: #F02F44;
		font-size: 16px;
		// margin: 0 0 0 12px;
	}

	&__privacy {
		width: (336 / $sp_ref) * 100vw;
		margin: 0 auto 12px;
		border: solid 1px #ccc;
		padding: 8px 8px;
		height: 120px;
		overflow: scroll;

		@media (--min) {
			width: 740px;
			margin: 0 auto 16px;
			height: 120px;
			overflow: scroll;
		}
	}

	&__privacyText {
		font-size: 13px;
	}

	&__check {
		display: flex;
		justify-content: center;
		margin: 20px 0 0;
	}

	&__checkLabel,
	label {
		display: flex;
		align-items: center;
	}

	&__checkBox,
	input[type="checkbox"] {
		display: block;
		width: auto;
		vertical-align: middle;
	}

	&__checkText {
		display: block;
		font-size: 12px;
		line-height: 1;
		vertical-align: middle;
		margin: 0 0 0 8px;

		@media (--min) {
			font-size: 14px;
		}
	}

	&__formError {
		color: #ac0d23;
		font-size: 13px;

		&:empty {
			margin: 0;
		}

		@media (--min) {
			font-size: 14px;
			grid-row: 2;
			grid-column: 2;
		}

		&--privacy {
			text-align: center;
		}
	}

	&__thanksText {
		text-align: center;
	}

	.mwform-checkbox-field-text {
		display: block;
		font-size: 12px;
		line-height: 1;
		vertical-align: middle;
		margin: 0 0 0 8px;

		@media (--min) {
			font-size: 14px;
		}
	}
}
